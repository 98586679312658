<template>
  <div id="client">
    <!-- 开头板块 -->
    <div class="home_bg w100">
      <img src="../assets/imgs/top-client2.jpg" alt="top-client1.png" class="w100" />
      <Header></Header>
      <Culture>
        <h3 slot="title1">资讯中心</h3>
        <p slot="title3">NEWS</p>
      </Culture>
    </div>
    <!-- news1 -->
    <div class="news w1200">
      <div class="news_l">
        <div class="news_l_info1 news_l_info" @click="gtoNews(mainNew[num - 6], num - 6)">
          <div>
            <h6>{{ mainNew[num - 6].title }}</h6>
            <span>{{ mainNew[num - 6].times }}</span>
          </div>
          <p>{{ mainNew[num - 6].content[1] }}</p>
        </div>
        <div class="news_l_info1 news_l_info" @click="gtoNews(mainNew[num - 5], num - 5)">
          <div>
            <h6>{{ mainNew[num - 5].title }}</h6>
            <span>{{ mainNew[num - 5].times }}</span>
          </div>
          <p>{{ mainNew[num - 5].content[1] }}</p>
        </div>
        <div class="news_l_info1 news_l_info" @click="gtoNews(mainNew[num - 4], num - 4)">
          <div>
            <h6>{{ mainNew[num - 4].title }}</h6>
            <span>{{ mainNew[num - 4].times }}</span>
          </div>
          <p>{{ mainNew[num - 4].content[1] }}</p>
        </div>
      </div>
      <div class="news_r">
        <img src="../assets/imgs/virtual.jpg" alt="虚拟现实.png" />
      </div>
    </div>
    <!-- news2 -->
    <div class="news w1200">
      <div class="news_r">
        <img src="../assets/imgs/mansion.jpg" alt="大厦.png" />
      </div>
      <div class="news_l">
        <div class="news_l_info1 news_l_info" @click="gtoNews(mainNew[num - 3], num - 3)">
          <div>
            <h6>{{ mainNew[num - 3].title }}</h6>
            <span>{{ mainNew[num - 3].times }}</span>
          </div>
          <p>{{ mainNew[num - 3].content[1] }}</p>
        </div>
        <div class="news_l_info1 news_l_info" @click="gtoNews(mainNew[num - 2], num - 2)">
          <div>
            <h6>{{ mainNew[num - 2].title }}</h6>
            <span>{{ mainNew[num - 2].times }}</span>
          </div>
          <p>{{ mainNew[num - 2].content[1] }}</p>
        </div>
        <div class="news_l_info1 news_l_info" @click="gtoNews(mainNew[num - 1], num - 1)">
          <div>
            <h6>{{ mainNew[num - 1].title }}</h6>
            <span>{{ mainNew[num - 1].times }}</span>
          </div>
          <p>{{ mainNew[num - 1].content[1] }}</p>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <Footer></Footer>
  </div>
</template>

<script>
import Culture from "../components/Culture";
import Header from "../components/Header";
import Footer from "../components/Footer/FootSec.vue";
export default {
  name: "Client",
  props: ['info','id'],
  data() {
    return {
      mainNew: [
        // ---传参方法不能完成实现，采用复制 , 以Home.vue 为准
        //--- 展示方式 ----  前三个带图放在home页上面 往后数6个放home下面（数量可以增加或减少） 最后6个放更多页（更多必须是6个 样式是这样的）
        // 内容content设置8个自然段，超出请自行添加
        //title 标题  // content[0] 是标题下面的 小字标注
        //content[0]之后的是  正文内容  // pic 内容每段附图
        //align : text-align  //content是1  : 流出空白位置 高度：1rem
        //info1
        {
          title: "工惠优选平台系统贯通电商平台壁垒",
          times: "2022-03",
          url: '@/assets/imgs/phone.jpg',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: ['', '2023.2月，经过与京东电商平台的协商，工惠优选平台系统实现了在商品品类、价格、配送、订单管理方面与京东商城产品的统一对接，会员可以在工惠优选平台自由选购，由京东统一配送。', '此次系统的贯通，给工会会员提供了更多、更优的产品选择，更快捷的配送渠道，使工会服务品质上升了到更高的台阶。', '', '', '', '', '', ''],
        },
        // info2
        {
          title: "中国劳动关系学院依托智慧工会服务管理平台系统深化校企合作协同发展模式",
          times: "2022-12",
          url: '@/assets/imgs/happy.jpg',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '12月，我公司战略合作伙伴FESCO与中国劳动关系学院就促进工会服务“产、学、研”深度融合签署战略合作协议。本次合作将充分发挥双方资源优势，打造政策研究、教育培训、学术交流成果转化的新高度。',
            '中国劳动关系学院作为全国总工会直属的高等学府，秉承70年发展历史、办学愿景，建设“劳动+”“工会+”学科特色、“一体两翼”发展格局，在工会和劳动关系领域充当科研智库，为企业、行业和社会提供智力支持。 学院坚持协同发展原则，积极开展校企合作、校校合作、校地合作、校会合作，汇聚多方资源推动内涵式发展。'
          ],
        },
        // info3
        {
          title: "庆祝“三八”国际劳动妇女节 —《形象美学与服装搭配》讲座活动",
          times: "2022-03",
          url: '@/assets/imgs/news/2023-3-8_motherDay.jpg',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '为庆祝2023年“三八”国际劳动妇女节，体现对女职工的关爱，倡导职场女性既要积极努力工作又要注重衣着打扮的理念，同时也为了使女职工通过穿搭及形象设计课程，体会到得体的着装不仅能给人留下深刻印象，而且可以增加个人魅力的重要性，并收获一份不一样的美丽体验，工会于2023年3月7日-3月8日举办庆“三八”女职工《形象美学与服装搭配》课程讲座活动，使女职工在提升自我休养、发现自身魅力中度过一个有意义的三八节日。'
          ],
        },
        // xiaozi1
        {
          title: "全国工会社会联络工作会议暨地方联动机制工作推进会在京召开",
          times: "2023-07-26",
          url: '',
          pic: ['', '', ''],
          align: 'left',
          content: [
            '',
            '全国工会社会联络工作会议暨地方联动机制工作推进会7月26日在京召开，对近年来工会社会联络工作进行总结，交流经验做法，研究部署当前和今后一个时期的目标任务。全国总工会党组书记、副主席、书记处第一书记徐留平出席会议并讲话，对各级工会贯彻落实习近平总书记重要指示批示精神、全力做好工会社会联络工作提出要求。',
            '会议回顾总结了近年来工会社会联络工作取得的积极进展。会议指出，全总和各级工会要深入学习贯彻习近平总书记重要指示批示精神，按照王东明同志要求，坚持以总体国家安全观为指导，将做好工会社会联络工作作为坚决拥护“两个确立”、坚决做到“两个维护”的重要举措紧抓不放，团结引导职工群众听党话、跟党走。要增强信心决心，把握形势风险，进一步提高政治判断力、政治领悟力、政治执行力，以“时时放心不下”的责任感，把“五个坚决”要求落到实处。',
          ],
        },
        {
          title: "全国总工会举行“关爱新就业形态劳动”新闻发布会",
          times: "2023-07-14",
          url: '',
          pic: ['', '', ''],
          align: 'left',
          content: [
            '',
            '全国总工会7月14日举行“关爱新就业形态劳动者”新闻发布会，介绍《深入推进新就业形态劳动者工会工作三年行动计划(2023一2025年)》有关情况和开展新就业形态劳动者工会工作的经验做法。根据三年行动计划”，未来3年将新发展新就业形态劳动者工会会员1000万人，基本实现工会组织对平台企业、头部企业和新就业形态劳动者的全覆盖。',
            '与此同时，各级工会将进一步深化“重点建、行业建、兜底建”模式，构建完善纵横交织、覆盖广泛的工会组织体系，尤其将深化互联网百强企业建会成果，推进互联网企业、平台企业扩面提质，并发挥新业态工会联合会直接发展会员的作用，推进网上入会、“扫码”入会等方式。值得关注的是，全总明确了新就业形态劳动者建会入会的新任务-2023年至2025年，平均每年新发展新就业形态劳动者会员300万人以上，最终达到1000万人入会。',
          ],
        },
        {
          title: "建党节",
          times: "2023-07-01",
          url: '',
          pic: ['', 'jdj.jpg', ''],
          align: 'center',
          content: [
            '',
            '致岁月铅华，敬红色传承',
          ],
        },
        {
          title: "祖国在我心 筑梦新时代",
          times: "2023-06-30",
          url: '',
          pic: ['', '', '', '', '', '', '', '', '', 'cpjng.png', '', 'cyc.png', '', '', 'jzdzd.png', '', 'wcqs.png', '', 'mfs.png', '', 'jxqbllz.png'],
          align: 'center',
          content: [
            '',
            '传承红色基因、赓续红色血脉',
            '七八月是被红色氛围包围的季节',
            '创新活动方式，丰富活动载体',
            '本期小通带来各式红色主题活动',
            '在体验学习过程中，实现“走心、入脑”',
            '对红色历史进行认识和深入了解',
            '1',
            '1',
            '昌平革命历史纪念馆',
            '革命历史纪念馆位于昌平烈士陵园，由昌平革命历史纪念馆，英雄纪念广场，英雄烈士墓地三部分组成，占地近80亩。纪念馆为上下两层建筑，一层为展厅，分为序言、京华古城燃星火，矢志不渝抗敌寇，红旗漫舞翻新历、尾厅五个部分，以图片和展品的形式展示了从1840年鸦片战争爆发到1949年新中国成立不同时期内，昌平人民的奋斗历史。',
            '长峪城',
            '长峪城位于昌平区西部。东西南三面临山，南邻雕窝沟，地处长峪峡谷，称长峪口，明正德年间筑关城，称长峪城。该城北依长城，南望镇边城，东邻臼羊城，战略位置十分险要，是明代京师北京防御的重要隘口。',
            '1',
            '焦庄户地道战遗址&纪念馆',
            '焦庄户地道战遗址纪念馆分为三个参观区，展馆参观区、地道参观区、抗战民居参观区。馆内收藏的革命文物，主要有挖地道使用的各种工具、民兵使用过的各种武器、群众使用过各种农具等。',
            '舞彩浅山国家徒步道',
            '舞彩浅山步道规划线路总长280公里，串联顺义龙湾屯、木林、张镇、大孙各庄及北石槽等浅山五镇，游览一期步道。',
            '妙峰山',
            '妙峰山风景名胜区，位于北京市西门头沟区境内，距市区55公里，总面积约20平方公里。景区以“古刹”、“奇松”、“怪石”、“异卉”而闻名。属太行山余脉，主峰海拔1291米，山势峭拔，有日出、晚霞、雾凇、山市等时令景观和千亩玫瑰花，有华北地区规模最大的传统朝圣庙会等。',
            '京西情报联络站',
            '平西情报联络站纪念馆，坐落于北京市门头沟区妙峰山旅游景区脚下,由涧沟村的关帝庙改建而成。情报站原址就位于村西的两个农家小院里。2009年4月开馆之际，北京市原市长焦若愚同志亲自题写了馆名。2016年3月，展馆进行了重新改造。',
          ],
        },
        {
          title: "端午安康",
          times: "2023-06-22",
          url: '',
          pic: ['', 'duanwu.jpg', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          align: 'center',
          content: [
            '',
            '团圆 恰逢其时',
            '生活 “粽”享美好'
          ],
        },
        // xiaozi6 多余的 到client1 不显示了
        {
          title: "全国总工会与国家卫健委共同召开全国用人单位托育服务工作现场推进会",
          times: "2023-06",
          url: '',
          pic: ['', '', '', ''],
          align: 'left',
          content: [
            '',
            '6月30日至7月1日，全国总工会与国家卫健委在浙江省杭州市共同召开全国用人单位托育服务工作现场推进会。会议围绕落实积极生育支持配套措施，构建普惠托育服务体系，协同推动用人单位托育服务工作高质量发展的目标，总结交流2022年全国用人单位托育服务工作。',
            '2023年4月，全国总工会、国家卫健委联合下发通知，公布75家用人单位为2022年全国爱心托育用人单位。目前，全国总工会已下拨750万元专项资金，以每个用人单位10万元的标准为全国爱心托育用人单位提供资金补助，省级工会进行不低于1:1的资金配套。下一步，全国总工会女职工部和国家卫健委人口家庭司将继续做好2023年全国爱心托育用人单位推荐申报工作，推动用人单位托育服务工作积极稳妥有序开展。',
          ],
        },
        {
          title: "全国总工会启动“工会送清凉、防暑保安康”活动",
          times: "2023-06",
          url: '',
          pic: ['', '', '', ''],
          align: 'left',
          content: [
            '',
            '中华全国总工会办公厅近日印发《关于开展“工会送清凉、防暑保安康”活动的通知》，对工会开展送清凉活动进行具体部署，进一步做好高温天气下工会劳动保护工作，切实维护广大职工在盛夏高温季节的安全健康权益，及时把党和政府以及工会组织的关爱送到广大职工心坎上。',
            '《通知》指出，各级工会要把“工会送清凉、防暑保安康”活动与学习贯彻党的二十大精神、习近平总书记关于工人阶级和工会工作的重要论述、习近平总书记在“五一”国际劳动节前夕的重要指示精神结合起来，与正在开展的学习贯彻习近平新时代中国特色社会主义思想主题教育结合起来，以“工会送清凉、防暑保安康”为契机，因地制宜开展形式多样的体现工会元素、职工喜闻乐见的防暑降温活动。',
          ],
        },
        {
          id: 4,
          title: "除了三八活动，工会还有哪些女职工相关工作？",
          times: "2023-03-30",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '我国工会女职工组织的组织形式是工会女职工委员会，就是咱们常说的“工会三委会”之一。（工会下设的三个委员会是：工会委员会、工会经费审查委员会、工会女职工委员会。）',
            '《工会女职工委员会工作条例》规定：',
            '一.女职工委员会与工会委员会同时建立。企业、事业单位、机关和其他社会组织等工会基层委员会有女会员十人以上的建立女职工委员会，不足十人的设女职工委员。',
            '二.女职工委员会委员由同级工会委员会提名，在充分协商的基础上产生，也可召开女职工大会或女职工代表大会选举产生。注重提高女劳动模范、一线女职工和基层工会女职工工作者在工会女职工委员会委员中的比例。',
            '三.在工会代表大会、职工代表大会、教职工代表大会中，女职工代表的比例应与女职工占职工总数的比例相适应。',
            '《工会女职工委员会工作条例》第六条强调，依法维护女职工在政治、经济、文化、社会和家庭等方面的合法权益和特殊利益,同一切歧视、虐待、摧残、迫害女职工的行为作斗争。',
            '其中特殊利益，是指女职工除享受国家规定的妇女应享有的合法权益外，还享受国家针对女职工生理、心理特点而制定的特殊利益保护措施，包括以下几个方面：',
            '1、女职工的特殊劳动保护',
            '国务院颁发的《女职工劳动保护特别规定》，就是为了减少和解决女职工在劳动中因生理特点造成的特殊困难，保护女职工健康。由于女性会经历经期、孕期、产期、哺乳期、更年期共五个时期的生理变化，也就对应需要进行“五期保护”。',
            '经期保护：《女职工禁忌从事的劳动范围》对于冷水作业、低温作业、体力强度作业、高处作业等规定了禁忌工作级别；',
            '孕期、产期与哺乳期保护：《社会保险法》《女职工劳动保护特别规定》等均对三期内用人单位的解除合同情形、以及女职工享有的待遇（产假、生育津贴、医疗费用等）作出了明确而具体的规定。',
            '更年期保护：《女职工保健工作规定》作出了相关更年期保健、疾病查治、工作安排等规定。',
            '2、注重心理调适工作',
            '工会女职工组织可灵活采用活动、讲座、心理EAP等多种方式，增强女职工的心理调试能力，减轻压力。',
            '3、促进工作场所性别平等',
            '工会女职工工作要关注侵害女职工劳动权益与人身权利的现象，从就业机会平等、职业发展机会平等、薪酬待遇平等、生育保护、预防和制止职场暴力和性骚扰等方面促进性别平等，可供参考的相关条文如下：',
            '《中华人民共和国妇女权益保障法》，由人大常委会于2022年10修订通过，自2023年1月1日起施行。',
            '《工作场所女职工特殊劳动保护制度（参考文本）》《消除工作场所性骚扰制度（参考文本）》，由人力资源和社会保障部、国家卫生健康委、最高人民检察院、全国总工会、中国企业联合会/中国企业家协会、全国工商联于2023年联合发布。',
            '《促进工作场所性别平等指导手册》，由中华全国总工会于2019年编著。',
          ],
        },
        {
          title: "春游方案比比皆是，为何工惠通的如此优秀？",
          times: "2023-03",
          url: '',
          pic: ['', '', '', '', 'ysws_left.jpg', 'ysws_right.jpg', '', '', '', '', '', '', '', '', 'fwzg_left.jpg', '', '', ''],
          align: 'center',
          content: [
            '',
            '游山玩水',
            '我们是“工会服务的行动派”',
            '无论您倾向于京郊赏春，还是市区打卡',
            '更或是出京游玩',
            '找工惠通，您必定心想事成',
            '青山绿水',
            '山村风情',
            '服务职工',
            '“潮流运动体验”、“趣味运动会”',
            '“嘉年华”、“传统景区一日游”',
            '“户外健步走”',
            '工惠通怀揣着探索和创造的火种',
            '提供春游新思路',
            '我们对职工的关怀如春光般明媚盎然',
            '我们对服务的追求如春潮般生生不息',
            '张灯结彩'
          ],
        },
        {
          title: "工会“她力量”，邂逅女神节",
          times: "2023-03",
          url: '',
          pic: ['', 'women_gift.jpg', '', 'women_serve.jpg', '', 'women_adm.jpg', '', ''],
          align: 'center',
          content: [
            '',
            '与时俱进的活动策划',
            '我们结合往年女神节活动数据和时下热门玩法，工惠通推陈出新，针对受众策划了法式花瓮、古风团扇、非遗系列等几十余种形式新颖的活动，多个维度宣传、推广女神节活动方案，大大提高了女神节活动的吸引力。',
            '专业高效的落地服务',
            '工惠通借助活动个性化定制，活动执行细节例会；全天候客户需求响应；活动效果收集分析四大服务模式，专业高效的为您的工会提供活动落地服务，让您省时、省力、省心， 让职工们在女神节这一天获得丰富多彩的体验。',
            '日臻完善的闭环管理',
            '在服务全过程中，我们会积极获取客户的建议与反馈，及时回应问题、解决困难，同时通过活动复盘量化活动评分，从前期相应、执行能力、临场效果等多方面强化数据闭环管理，总结经验与不足，从而提升工惠通女神节活动的专业性和趣味性。',
            '随着春天徐徐展开，工惠通还将为广大工会组织带来更多样、更有趣的慰问与活动创意，让“工会的未来主义”普惠每一位职工。'
          ],
        },
        //client1  取后6个
        {
          title: "《女职工劳动保护特划规定》已经2012年4月 13 日国各院策 200次常务会议通过，现予公布，自公布之日起旅行",
          times: "2023-03",
          url: '',
          pic: ['', 'womenLaborLaw1.png', 'womenLaborLaw2.png', 'womenLaborLaw3.png', 'womenLaborLaw4.png', '', '', ''],
          align: 'left',
          content: [
            ''
          ],
        },
        {
          id: 5,
          title: "全国总工会党组召开扩大会议传达学习全国两会精神",
          times: "2023-03",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '全国总工会党组3月14日召开扩大会议传达学习全国两会精神。全国人大常委会副委员长、中华全国总工会主席王东明对全总和各级工会深入学习贯彻全国两会精神特别是习近平总书记系列重要讲话精神提出明确要求。会议强调，各级工会要把思想和行动统一到全国两会精神上来，准确把握工会工作面临的新机遇新要求，在党和国家工作大局中更好发挥工会组织作用，团结动员亿万职工激扬团结奋斗力量，为全面建成社会主义现代化强国、全面推进中华民族伟大复兴作出应有的贡献。',
            '会议强调，全总和各级工会要深刻认识全国两会对形势任务作出的科学判断，按照党的二十大的战略部署，适应实现高质量发展、推进中国式现代化建设的要求，认真履行政治责任和基本职责，不断深化工会改革和建设，把党中央、国务院关于经济社会发展的各项决策部署理解到位、把握到位、落实到位，组织动员亿万职工在推进强国建设、民族复兴的新征程上创造新辉煌、铸就新伟业。'
          ],
        },
        {
          id: 6,
          title: "2023年全国工会县级以上机关干部赴基层蹲点工作启动",
          times: "2023-03",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '全总发出通知，启动2023年工会县级以上机关干部赴基层蹲点工作，选派干部深入工会组建难点多、工会工作比较薄弱、劳动关系情况比较复杂的乡镇 (街道)、开发区(工业园区) 工会和县级以下区域性、行业性工会联合会，为基层和职工办实事、解难题。',
            '通知要求，各级工会要把职工思想政治引领、产业工人队伍建设改革、新就业形态劳动者建会入会、构建和谐劳动关系、维护劳动领域政治安全、加强智慧工会建设、县级工会加强年”专项工作等7项工作纳入蹲点任务，省级和市级总工会一般选派3%至5%的机关干部赴基层蹲点。全总机关率先派出8个蹲点组，由局级干部带队，共26名干部陆续前往各地蹲点。',
            '2021年和2022年，全国县级以上工会机关共选派3万多名干部，组成1万多个小组赴基层蹲点，累计访谈基层干部、一线职工54万人，慰问帮扶困难职工35万人，指导新建基层工会组织6万多个，新发展工会会员425万多人，帮助8万余个基层工会实施规范化建设，宣讲劳动法律法规和工会政策2.5万场次，提供法律援助覆盖职工23万人。'
          ],
        },
        {
          title: "全力促进劳动关系和谐稳定-江广平委员代表全国总工会在全国政协十四届一次会议视频会议上的大会发言",
          times: "2023-03",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '中共二十大强调，“健全劳动法律法规，完善劳动关系协商协调机制”。3月6日，习近平总书记在看望参加政协会议的民建、工商联界委员时强调，民营企业要在企业内部积极构建和谐劳动关系，推动构建全体员工利益共同体，让企业发展成果更公平惠及全体员工。',
            '为此，建议:',
            '一是多措并举稳就业，端牢“金饭碗”。',
            '二是完善劳动报酬制度，充实“钱袋子”。',
            '三是强化劳动关系三方机制，用好“助推器”。',
            '四是健全劳动关系矛盾预防和调处机制，织密“防护网”。',
            '五是充分发挥工会作用，当好“娘家人”。'
          ],
        },
        {
          id: 11,
          title: "全国总工会发布第二批提升职工生活品质试点单位名单",
          times: "2022-03",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '近日，全国总工会下发《关于发布第二批提升职工生活品质试点单位名单的通知》，确定北京市朝阳区工会帮扶中心等20家单位、中国邮政集团有限公司北京市分公司等100家企业(工业园区)为第二批提升职工生活品质全总试点单位。全总对列入试点的帮扶中心安排引导性资金50万元、试点企业(工业园区)安排引导性资金5万元，用干培育创新帮扶服务职工项目， 据了解，2021年9月，全总部署开展提升职工生活品质工作，安排1500万元专项资金予以支持，将20家工会帮扶中心和50家企业(工业园区)纳入全总试点单位。据全总权益保障部有关负责人介绍，截至目前，31个省(区、市)和新疆生产建设兵团总工会均已在全总试点基础上开展省级试点工作，推动落地了一批帮扶服务职工项目，培育储备了一批服务职工基地、关爱职工公益伙伴，形成了一系列因地制宜各具特色的经验做法，取得了一系列可复制可推广的阶段性成果。'
          ],
        },
        {
          id: 12,
          title: "智慧工会服务管理平台系统携手招商银行北京分行 共谋智慧工会服务新蓝图",
          times: "2020-06-23",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '2020年6月23日，招商银行股份有限公司北京分行就进驻“智慧工会服务管理平台系统”签署战略合作协议。',
            '招商银行自2019下半年起，围绕客户和科技两大关键点深化战略转型，重新定义银行服务对象和银行服务边界。本次合作，不仅实现了招行内部工会组织的高效运作，更创造了一个链接供应商、工会组织、工会会员的智慧工会服务平台，在提升职工的满意度和获得感的同时，引领和推动智慧工会服务跨越式发展。'
          ],
        },
        {
          id: 13,
          title: "智慧工会服务管理平台系统荣获“2020中国信息技术创新应用”大奖",
          times: "2020-06-30",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '6月30日，中国信息协会主办、信息化观察网和中国信息协会传媒中心承办的2020 第三届中国信息技术主管大会在京召开。以“革新·数字化升级”为主题，大会邀请了国内信息化领域权威专家和知名中外企业CIO，探讨新技术、新思维、新模式赋能企业实现业务模式的创新升级变革，并发布了中国信息技术优秀项目。',
            '北京雷博优尼科技有限公司与FESCO强强联合，凭借“智能+”应用敏捷创新和软件开发实力，智慧工会服务管理平台系统荣获“2020中国信息技术创新应用”。',
            '北京雷博优尼科技有限公司具备专业的计算机设计开发能力，时刻关注与传统业务领域的合作，在改变与提升传统业务的模式与效率上投入大力，挖掘和扩展了数字化服务的维度和深度。',
            '智慧工会服务管理平台，助力客户打造智慧工会，实现工会信息化、数字化转型，直达客户工会会员及工会组织。平台通过组织在线、沟通在线和协调在线，实现会员与会员、会员与组织、组织与组织的数字化管理，不仅激发工会创造力，同时实现了工会组织的高效运作，更创造了一个链接供应商、工会组织、工会会员的智慧工会服务平台。'
          ],
        },
      ],
      num: 6
    };
  },
  components: {
    Header,
    Culture,
    Footer,
  },
  methods: {
    // 回到顶部
    gtoNews(item, index) {
      item = JSON.stringify(item)
      this.$router.push({ name: "News", query: { id: index, info: item } });
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    this.num = this.mainNew.length
  }
};
</script>
<style scoped lang="less">
#client .news {
  height: 28.125rem;
  display: flex;
  justify-content: space-between;
  margin: 2rem auto 1.5rem auto;
}

.news_l {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.news_l a {
  height: 31%;
}

.news_l_info {
  border-bottom: 0.0625rem #f0f0f0 solid;
  padding: 0 0.75rem;
  height: 100%;
}

.news_l_info>div {
  display: flex;
  justify-content: space-between;
  // mar
}

.news_l_info h6 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #333333;
  margin: 0.2rem 0 0.8rem 0;
  overflow: hidden;
  white-space: nowrap;
  max-width: 30.625rem;
  text-overflow: ellipsis;
}

.news_l_info p {
  font-size: 1.125rem;
  font-weight: 400;
  color: #999999;
  line-height: 1.875rem;
  display: -webkit-box;
  /*将对象作为弹性伸缩盒子模型显示*/
  overflow: hidden;
  /*超出的文本隐藏*/
  white-space: normal !important;
  /*溢出不换行(white-space: nowrap;)*/
  text-overflow: ellipsis;
  /*溢出用省略号显示*/
  word-wrap: break-word;
  /*用来标明是否允许浏览器在单词内进行断句，这是为了防止当一个字符串太长而找不到它的自然断句点时产生溢出现象*/
  -webkit-line-clamp: 3;
  /*这个属性不是css的规范属性，需要组合上面两个属性，表示显示的行数。*/
  -webkit-box-orient: vertical;
  /*从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）*/
}

.news_l_info span {
  font-size: 1rem;
  font-weight: 400;
  color: #999999;
  max-width: 8rem;
  line-height: 1.75rem;
}

.news_r {
  width: 48%;
  height: 100%;
  text-align: center;
}

.news_r img {
  height: 100%;
}
</style>
